
/* Counter Wrapper */
.counter {
    display: flex;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Button Styling */
.counter button {
    background-color: #5c5f63;
    color: white;
    border: none;
    padding: 12px 10px;
    cursor: pointer;
    font-size: 17px;
    transition: background-color 0.3s ease;
}

.counter button:hover {
    background-color: #4a4d51;
}

/* Input Field Styling */
.counter input {
    width: 50px;
    text-align: center;
    font-size: 24px;
    border: none;
    outline: none;
    background-color: #fff;
    margin: 0;
}

/* Responsive Design */
@media (max-width: 480px) {
.counter button {
        padding: 10px 12px;
        font-size: 17px;
    }

.counter input {
        width: 50px;
        font-size: 17px;
    }
}
