body {
    margin: 0 !important;
    flex-basis: auto;
    font-family: 'Source Sans Pro', sans-serif !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none !important;
}

.nav-link {
    color: #000;
}

.bg {

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.div-center {
    margin: auto;
    width: 60%;
    border: 3px solid #73AD21;
    padding: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.cancel-date-input {
    /*border: 0;*/
    /*background: #e5e8ea;*/
    /*border-bottom: 1px solid #000;*/
    /*outline: none;*/
    /*height: 56px;*/
    /*border-top-left-radius: 5px;*/
    /*border-top-right-radius: 5px;*/
    /*text-indent: 8px;*/
    /*width: 100%*/
}

.dialog {
    box-shadow: 0 8px 6px -6px black;
    position: static;
    left: 20%;
    top: 10%;
}

.image {
    width: 300px;
}

.pd-img {
    float: left;
    margin: 10px;
}

.product-td {
    height: 40px;
}

.product-td > div.pd-img {
    height: 80px;
    width: 80px;

}

td.product-td > strong {
    line-height: 90px;
}
.product-td > strong {
    line-height: 60px;
}

.status-td {
    line-height: 50px;
}

.total-td > span {
    line-height: 50px;
    font-size: 22px;
}

@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@page {
    size: auto;
    margin: 5mm;
}

.tableFixHead thead th { position: sticky; top: 0; z-index: 10}
.tableFixBody tbody th { position: sticky; top: 0; z-index: 10 }

/* Just common table stuff. Really. */
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }

.antDesignTbDark .ant-table-thead > tr > th {
    background-color: rgb(33 37 41) !important; /* Change this to your desired color */
    color: white; /* Optional: change text color */
}
.antDesignTbLight .ant-table-thead > tr > th {
    background-color: white !important; /* Change this to your desired color */
    color: black; /* Optional: change text color */
}
